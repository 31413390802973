<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item"><a href="#/daughter-2">我女兒懷孕了</a></li>
              <li class="breadcrumb-item active" aria-current="page"><a href="#">小孩生下後會被帶走嗎</a></li>
            </ol>
          </nav>
        </section>
        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="text-box">
              <p class="step-text" data-aos="fade-up">ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up">為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="select-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜小孩生下後會被帶走嗎｜</h2>
                </div>
                <div class="step-header">
                  <img data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                    <p data-aos="fade-up">當面臨人生岔路中做出選擇，家人的包容與支持是YOUNG父母最大的動力，面對此刻的人生考題，家人所給予的也是他們經歷路程的一部分，為YOUNG父母指向正確的道路，家庭的關係不再疏遠。
                    </p>
                  <hr data-aos="fade-up">
                </div>
                <div class="select-menu">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>留養．寄養．出養，選擇最適當照顧方式</h3>
                        <p class="mb-20">您的女兒需得到父母、監護人或伴侶的支持，一起生養孩子，而且還要能繼續學業或工作，才能夠做個好父母。若伴侶、先生或家人有不同意見，你們需協商找出共識，但也不需違背心意做出被迫的決定，你們仍有寄養、出養的管道可選擇。<br>
                        懷孕過程中若需要協助或安置於中途之家，也可透過社會福利管道或諮詢服務尋求協助。
                        </p>
                        <ul class="list mb-60">
                          <li>寄養：<br>
                            若選擇寄養，將來可於合宜時機帶回自己養。可以請社福提供相關資訊，尋找寄養家庭。<br>
                            寄養是當兒童的原生家庭暫時無法提供適當照顧，必須安排一個替代性的家庭來對此兒童提供計畫性的照顧。
                          </li>
                          <li>收養/出養：<br>
                            若考慮給人收養(出養，可請社福提供相關資訊，尋找出養機構及相關資源管道。<br>
                            收養（出養）當兒童的原生父母或家庭發生困難，使他無法和父母共同生活，因此需透過社會福利機構安排，替孩子另找一個家庭，提供使他生活更有保障的養育方式。
                          </li>
                        </ul>
                      </li>
                      <li data-aos="fade-up">
                        <h3>相關資源</h3>
                        <ul class="list mb-60">
                          <li>1. 生產育嬰福利：
                            <a href="https://dep.mohw.gov.tw/DOPL/cp-5054-57840-101.html" target="_blank">送子鳥資訊網</a>
                          </li>
                          <li>2. 1957福利諮詢專線(社會救助、兒少福利、家庭福利)：
                            <a href="tel:1957">1957</a>
                          </li>
                          <li>
                            <a href="https://babyedu.sfaa.gov.tw/mooc/index.php" target="_blank">3. 社家署育兒親職網</a>
                          </li>
                          <li>
                            <a href="https://www.mohw.gov.tw/lp-88-1-40.html" target="_blank">4. 衛生福利e寶箱</a>(低收入戶及中低收入戶、婦女福利、兒少福利、托育服務)
                          </li>
                          <li>
                            <a href="https://257085.sfaa.gov.tw/" target="_blank">5. 未成年懷孕求助網站</a>
                          </li>
                          <li>
                            6. 全國未成年懷孕諮詢專線：<a href="tel:0800257085">0800-257-085</a>
                          </li>
                          <li>
                            7. 參考報導：<a href="https://vita.tw/%E9%99%AA%E4%BC%B4%E6%87%B7%E5%AD%95%E9%9D%92%E5%B0%91%E5%A5%B3-%E5%8B%B5%E9%A6%A8%E4%B8%89%E9%9A%8E%E6%AE%B5%E6%9C%8D%E5%8B%99-4a256ec00c77" target="_blank">陪伴懷孕青少女–勵馨三階段服務</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Article></Article>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
import Article from '../../components/Article.vue'

export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  components: {
    Consult,
    Article
  }
}
</script>
